import React from 'react';
import { IImage } from 'gatsby-plugin-image';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import Image from '../../ui/Image';
import ContentSlider from '../../ui/ContentSlider';
import * as styles from './styles.module.scss';
import { ILink } from '../../ui/FlexibleLink/type';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import FlexibleLink from '../../ui/FlexibleLink';

type ImageSliderContentTeaserProps = {
  headline: string;
  text: Document;
  link: ILink;
  entries: Array<ImageSliderContentTeaserEntry>;
  componentType?: boolean;
  images: Array<IImage>;
};

type ImageSliderContentTeaserEntry = {
  image: IImage;
  headline: string;
  text: string;
  link: ILink;
};

const ImageSliderContentTeaser = ({
  headline,
  text,
  link,
  entries,
  componentType,
  images,
}: ImageSliderContentTeaserProps) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    cennter: true,
    className: 'center',
    centerPadding: '15px',
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {componentType ? (
        <Container fullWidth additionalClass={styles.containerSlider}>
          <Row>
            <Col desktop={6} tablet={6} mobile={12}>
              <Headline level={2} color="Black" additionalClass="mb-20 desktop: mb-30">
                {headline}
              </Headline>
              <RichText content={text} additionalClass="mb-20 desktop: mb-30" />
              {link && <FlexibleLink link={link} />}
            </Col>
          </Row>
          <Row additionalClass="mt-60">
            <Col desktop={12} tablet={12} mobile={12}>
              <ContentSlider additionalSettings={settings}>
                {entries.map(({ image, headline: entryHeadline, link: entryLink, text: entryText }, idx) => (
                  <Row key={idx}>
                    <Col desktop={6} mobile={12}>
                      <Image {...image} additionalClass={styles.img} />
                    </Col>
                    <Col desktop={6} mobile={12} additionalClass="flex">
                      <div className={styles.textWrapper}>
                        <Headline level={3} color="Black" additionalClass="mb-20">
                          {entryHeadline}
                        </Headline>
                        <RichText content={entryText} additionalClass={styles.text} />
                        {entryLink && <FlexibleLink link={entryLink} additionalClass="mt-20 desktop: mt-40" />}
                      </div>
                    </Col>
                  </Row>
                ))}
              </ContentSlider>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container additionalClass={styles.containerGallery} fullWidth>
          <div className={styles.flexContainer}>
            {images.map((image, idx) => (
              <Image {...image} additionalClass={styles.img} key={idx} />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default ImageSliderContentTeaser;
